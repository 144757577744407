import {GetStaticProps} from 'next'
import Layout from '../components/layout'
import ParagraphMapper from '../components/paragraphs/ParagraphMapper'
import Hero from '../components/shared/Hero'
import {get404ErrorPage} from '../lib/api'
import {
  ContentPageNode,
  FooterMenuLink,
  MenuLink,
  Node,
} from '../types/content-types'
import {Response} from '../types/api-types'
import {serverSideTranslations} from 'next-i18next/serverSideTranslations'
import {SearchResponse} from '@algolia/client-search'
import {SSRConfig} from 'next-i18next'
import {SharedDataStrategy} from '../lib/cacheStrategies/sharedDataStrategy'
import {SharedDataRealtime} from '../lib/cacheStrategies/sharedDataStrategy.realtime'
import {
  localizedCacheFileExists,
  MENUS_CACHEFILENAME,
} from '../lib/cacheFileHelper'
import {SharedDataFileSystem} from '../lib/cacheStrategies/sharedDataStrategy.filesystem'
import {SharedDataRemoteCache} from '../lib/cacheStrategies/sharedDataStrategy.remoteCache'
import {getTranslations} from '../lib/get-dictionary-items'

export type Error404Page = {
  page: Node | null
  breadCrumb: {label: string; path: string}[]
  mainMenuItems: MenuLink[] | null
  functionalNavItems: MenuLink[] | null
  socialMenuItems: MenuLink[] | null
  footerMenuItems: FooterMenuLink[] | null
  doormatMenuItems: MenuLink[] | null
  blockNewsResourcesAlgolia: SearchResponse | null
  siteSwitchMenuItems: MenuLink[] | null
  topNavigationMenuItems: MenuLink[] | null
  resources: SearchResponse | null
  country: string
  environment: string
  response: Response | null
  doormatButtonMenuItems: Response | null
  error404Page: ContentPageNode
} & SSRConfig

const Custom404 = ({
  error404Page,
  mainMenuItems,
  functionalNavItems,
  socialMenuItems,
  footerMenuItems,
  siteSwitchMenuItems,
  topNavigationMenuItems,
  doormatMenuItems,
  response,
  page,
  country,
}: Error404Page) => (
  <Layout
    mainMenuItems={mainMenuItems}
    functionalNavItems={functionalNavItems}
    doormatMenuItems={doormatMenuItems}
    socialMenuItems={socialMenuItems}
    footerMenuItems={footerMenuItems}
    siteSwitchMenuItems={siteSwitchMenuItems}
    topNavigationMenuItems={topNavigationMenuItems}
    page={page}
  >
    <Hero response={response} page={error404Page} />
    {error404Page?.relationships.field_paragraphs?.data.map(paragraph => (
      <ParagraphMapper
        paragraph={paragraph}
        response={response}
        key={paragraph.id}
        country={country}
      />
    ))}
  </Layout>
)

export const getStaticProps: GetStaticProps = async context => {
  const locale =
    context.locale === 'default' ? 'nl-be' : context.locale?.toLowerCase()
  let sharedDataStrategy: SharedDataStrategy
  if (process.env.NODE_ENV !== 'production') {
    // npm run dev
    sharedDataStrategy = new SharedDataRealtime()
  } else if (
    await localizedCacheFileExists(MENUS_CACHEFILENAME, locale ?? 'nl-be')
  ) {
    // buildtime OR localhost
    sharedDataStrategy = new SharedDataFileSystem()
  } else if (context.preview) {
    // preview OR runtime
    sharedDataStrategy = new SharedDataRealtime()
  } else {
    sharedDataStrategy = new SharedDataRemoteCache()
  }

  const langCode = context.locale!.toLowerCase()
  const error404PageResponse = await get404ErrorPage(langCode)
  const error404Page = error404PageResponse?.included?.find(
    el => el.type === 'node--404_page_not_found',
  ) as ContentPageNode

  const environment = process.env.APP_ENV

  const [menus, _] = await Promise.all([
    sharedDataStrategy.getAllMenuLinks({...context, locale}),
    getTranslations(),
  ])

  await getTranslations()

  return {
    props: {
      ...(await serverSideTranslations(context.locale!, ['common'])),
      mainMenuItems: menus?.menus?.mainMenu ?? null,
      functionalNavItems: menus?.menus?.functionalMenu ?? null,
      socialMenuItems: menus?.menus?.socialMenu ?? null,
      doormatMenuItems: menus?.menus?.doormatMenu ?? null,
      footerMenuItems: menus?.menus?.footerMenu ?? null,
      siteSwitchMenuItems: menus?.menus?.siteSwitch ?? null,
      topNavigationMenuItems: menus?.menus?.topMenu ?? null,
      error404Page: error404Page ?? null,
      page: error404Page ?? null,
      environment,
      country: process.env.COUNTRY!,
    },
    revalidate: process.env.APP_ENV === 'prd' ? 1800 : 60,
  }
}

export default Custom404
